ol.lst-kix_ys6j0jd88rev-2.start {
    counter-reset: lst-ctn-kix_ys6j0jd88rev-2 0
}

.lst-kix_yplpvhewoxry-6 > li:before {
    content: "\0025cf   "
}

.lst-kix_yplpvhewoxry-7 > li:before {
    content: "o  "
}

ol.lst-kix_list_7-0 {
    list-style-type: none
}

.lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1
}

.lst-kix_yplpvhewoxry-5 > li:before {
    content: "\0025aa   "
}

.lst-kix_list_6-1 > li {
    counter-increment: lst-ctn-kix_list_6-1
}

.lst-kix_j92y1nrorbj-0 > li {
    counter-increment: lst-ctn-kix_j92y1nrorbj-0
}

.lst-kix_ikuk634edow9-1 > li {
    counter-increment: lst-ctn-kix_ikuk634edow9-1
}

.lst-kix_yplpvhewoxry-0 > li:before {
    content: "" counter(lst-ctn-kix_yplpvhewoxry-0, lower-latin) ". "
}

.lst-kix_yplpvhewoxry-8 > li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_7-4.start {
    counter-reset: lst-ctn-kix_list_7-4 0
}

.lst-kix_list_5-0 > li {
    counter-increment: lst-ctn-kix_list_5-0
}

ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0
}

.lst-kix_lxmvnmtqd0jr-2 > li {
    counter-increment: lst-ctn-kix_lxmvnmtqd0jr-2
}

ol.lst-kix_list_7-5 {
    list-style-type: none
}

ol.lst-kix_list_7-6 {
    list-style-type: none
}

ol.lst-kix_list_7-7 {
    list-style-type: none
}

ol.lst-kix_list_7-8 {
    list-style-type: none
}

ol.lst-kix_list_7-1 {
    list-style-type: none
}

.lst-kix_gn0wvmapmitf-2 > li {
    counter-increment: lst-ctn-kix_gn0wvmapmitf-2
}

ol.lst-kix_list_7-2 {
    list-style-type: none
}

ol.lst-kix_list_7-3 {
    list-style-type: none
}

ol.lst-kix_lxmvnmtqd0jr-8.start {
    counter-reset: lst-ctn-kix_lxmvnmtqd0jr-8 0
}

ol.lst-kix_list_7-4 {
    list-style-type: none
}

.lst-kix_bk1ome99d61y-4 > li:before {
    content: "" counter(lst-ctn-kix_bk1ome99d61y-4, lower-latin) ". "
}

.lst-kix_gn0wvmapmitf-0 > li:before {
    content: "" counter(lst-ctn-kix_gn0wvmapmitf-0, lower-latin) ". "
}

.lst-kix_bk1ome99d61y-3 > li:before {
    content: "" counter(lst-ctn-kix_bk1ome99d61y-3, decimal) ". "
}

.lst-kix_bk1ome99d61y-7 > li:before {
    content: "" counter(lst-ctn-kix_bk1ome99d61y-7, lower-latin) ". "
}

ol.lst-kix_list_5-3.start {
    counter-reset: lst-ctn-kix_list_5-3 0
}

.lst-kix_bk1ome99d61y-0 > li:before {
    content: "" counter(lst-ctn-kix_bk1ome99d61y-0, lower-latin) ". "
}

.lst-kix_bk1ome99d61y-8 > li:before {
    content: "" counter(lst-ctn-kix_bk1ome99d61y-8, lower-roman) ". "
}

.lst-kix_gn0wvmapmitf-1 > li:before {
    content: "" counter(lst-ctn-kix_gn0wvmapmitf-1, lower-latin) ". "
}

.lst-kix_gn0wvmapmitf-2 > li:before {
    content: "" counter(lst-ctn-kix_gn0wvmapmitf-2, lower-roman) ". "
}

.lst-kix_list_4-3 > li {
    counter-increment: lst-ctn-kix_list_4-3
}

.lst-kix_bk1ome99d61y-2 > li:before {
    content: "" counter(lst-ctn-kix_bk1ome99d61y-2, lower-roman) ". "
}

.lst-kix_bk1ome99d61y-1 > li:before {
    content: "" counter(lst-ctn-kix_bk1ome99d61y-1, lower-latin) ". "
}

.lst-kix_f3r5o9u9gdu9-7 > li {
    counter-increment: lst-ctn-kix_f3r5o9u9gdu9-7
}

ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 0
}

.lst-kix_yplpvhewoxry-1 > li:before {
    content: "o  "
}

ol.lst-kix_gn0wvmapmitf-3.start {
    counter-reset: lst-ctn-kix_gn0wvmapmitf-3 0
}

.lst-kix_yplpvhewoxry-2 > li:before {
    content: "\0025aa   "
}

.lst-kix_yplpvhewoxry-3 > li:before {
    content: "\0025cf   "
}

.lst-kix_list_3-2 > li {
    counter-increment: lst-ctn-kix_list_3-2
}

.lst-kix_bk1ome99d61y-6 > li:before {
    content: "" counter(lst-ctn-kix_bk1ome99d61y-6, decimal) ". "
}

.lst-kix_yplpvhewoxry-4 > li:before {
    content: "o  "
}

.lst-kix_bk1ome99d61y-6 > li {
    counter-increment: lst-ctn-kix_bk1ome99d61y-6
}

.lst-kix_bk1ome99d61y-5 > li:before {
    content: "" counter(lst-ctn-kix_bk1ome99d61y-5, lower-roman) ". "
}

.lst-kix_list_7-2 > li {
    counter-increment: lst-ctn-kix_list_7-2
}

.lst-kix_list_5-0 > li:before {
    content: " "
}

ol.lst-kix_list_6-0 {
    list-style-type: none
}

ol.lst-kix_list_6-1 {
    list-style-type: none
}

.lst-kix_list_5-4 > li {
    counter-increment: lst-ctn-kix_list_5-4
}

.lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4
}

ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0
}

.lst-kix_list_5-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_5-3, upper-latin) ") "
}

.lst-kix_list_5-2 > li:before {
    content: "(" counter(lst-ctn-kix_list_5-2, lower-roman) ") "
}

.lst-kix_gn0wvmapmitf-8 > li:before {
    content: "" counter(lst-ctn-kix_gn0wvmapmitf-8, lower-roman) ". "
}

.lst-kix_list_5-1 > li:before {
    content: "(" counter(lst-ctn-kix_list_5-1, lower-latin) ") "
}

.lst-kix_list_5-7 > li:before {
    content: " "
}

.lst-kix_gn0wvmapmitf-5 > li:before {
    content: "" counter(lst-ctn-kix_gn0wvmapmitf-5, lower-roman) ". "
}

ol.lst-kix_f3r5o9u9gdu9-6.start {
    counter-reset: lst-ctn-kix_f3r5o9u9gdu9-6 0
}

.lst-kix_list_5-6 > li:before {
    content: " "
}

.lst-kix_list_5-8 > li:before {
    content: " "
}

.lst-kix_gn0wvmapmitf-4 > li:before {
    content: "" counter(lst-ctn-kix_gn0wvmapmitf-4, lower-latin) ". "
}

.lst-kix_gn0wvmapmitf-6 > li:before {
    content: "" counter(lst-ctn-kix_gn0wvmapmitf-6, decimal) ". "
}

.lst-kix_gn0wvmapmitf-7 > li:before {
    content: "" counter(lst-ctn-kix_gn0wvmapmitf-7, lower-latin) ". "
}

.lst-kix_gn0wvmapmitf-3 > li:before {
    content: "" counter(lst-ctn-kix_gn0wvmapmitf-3, decimal) ". "
}

ol.lst-kix_list_6-6 {
    list-style-type: none
}

ol.lst-kix_list_6-7 {
    list-style-type: none
}

.lst-kix_f3r5o9u9gdu9-3 > li {
    counter-increment: lst-ctn-kix_f3r5o9u9gdu9-3
}

.lst-kix_list_5-4 > li:before {
    content: " "
}

ol.lst-kix_list_6-8 {
    list-style-type: none
}

.lst-kix_list_5-5 > li:before {
    content: " "
}

ol.lst-kix_list_6-2 {
    list-style-type: none
}

ol.lst-kix_list_6-3 {
    list-style-type: none
}

ol.lst-kix_list_6-4 {
    list-style-type: none
}

ol.lst-kix_list_6-5 {
    list-style-type: none
}

ol.lst-kix_ikuk634edow9-1.start {
    counter-reset: lst-ctn-kix_ikuk634edow9-1 0
}

.lst-kix_yplpvhewoxry-0 > li {
    counter-increment: lst-ctn-kix_yplpvhewoxry-0
}

.lst-kix_list_6-1 > li:before {
    content: "" counter(lst-ctn-kix_list_6-1, decimal) ". "
}

.lst-kix_list_6-3 > li:before {
    content: "" counter(lst-ctn-kix_list_6-3, lower-roman) ". "
}

ol.lst-kix_ikuk634edow9-8.start {
    counter-reset: lst-ctn-kix_ikuk634edow9-8 0
}

.lst-kix_list_6-5 > li {
    counter-increment: lst-ctn-kix_list_6-5
}

.lst-kix_ikuk634edow9-8 > li {
    counter-increment: lst-ctn-kix_ikuk634edow9-8
}

.lst-kix_list_6-8 > li {
    counter-increment: lst-ctn-kix_list_6-8
}

.lst-kix_list_6-0 > li:before {
    content: "" counter(lst-ctn-kix_list_6-0, decimal) ". "
}

.lst-kix_list_6-4 > li:before {
    content: "" counter(lst-ctn-kix_list_6-4, lower-latin) ". "
}

ol.lst-kix_lxmvnmtqd0jr-1.start {
    counter-reset: lst-ctn-kix_lxmvnmtqd0jr-1 0
}

.lst-kix_bk1ome99d61y-2 > li {
    counter-increment: lst-ctn-kix_bk1ome99d61y-2
}

.lst-kix_gn0wvmapmitf-6 > li {
    counter-increment: lst-ctn-kix_gn0wvmapmitf-6
}

.lst-kix_list_3-6 > li {
    counter-increment: lst-ctn-kix_list_3-6
}

ol.lst-kix_j92y1nrorbj-1.start {
    counter-reset: lst-ctn-kix_j92y1nrorbj-1 0
}

.lst-kix_list_6-2 > li:before {
    content: "" counter(lst-ctn-kix_list_6-2, lower-latin) ". "
}

ol.lst-kix_gn0wvmapmitf-8.start {
    counter-reset: lst-ctn-kix_gn0wvmapmitf-8 0
}

.lst-kix_list_2-5 > li {
    counter-increment: lst-ctn-kix_list_2-5
}

.lst-kix_list_2-8 > li {
    counter-increment: lst-ctn-kix_list_2-8
}

ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0
}

.lst-kix_list_6-8 > li:before {
    content: "" counter(lst-ctn-kix_list_6-8, lower-roman) ". "
}

.lst-kix_s8s4bw91u5cp-4 > li {
    counter-increment: lst-ctn-kix_s8s4bw91u5cp-4
}

.lst-kix_list_6-5 > li:before {
    content: "" counter(lst-ctn-kix_list_6-5, lower-roman) ". "
}

.lst-kix_list_6-7 > li:before {
    content: "" counter(lst-ctn-kix_list_6-7, lower-latin) ". "
}

.lst-kix_ikuk634edow9-5 > li {
    counter-increment: lst-ctn-kix_ikuk634edow9-5
}

ol.lst-kix_j92y1nrorbj-8.start {
    counter-reset: lst-ctn-kix_j92y1nrorbj-8 0
}

.lst-kix_list_6-6 > li:before {
    content: "" counter(lst-ctn-kix_list_6-6, decimal) ". "
}

.lst-kix_s8s4bw91u5cp-6 > li:before {
    content: "" counter(lst-ctn-kix_s8s4bw91u5cp-6, lower-latin) ". "
}

.lst-kix_list_7-4 > li:before {
    content: "" counter(lst-ctn-kix_list_7-4, lower-latin) ". "
}

.lst-kix_list_7-6 > li:before {
    content: "" counter(lst-ctn-kix_list_7-6, decimal) ". "
}

.lst-kix_j92y1nrorbj-0 > li:before {
    content: "" counter(lst-ctn-kix_j92y1nrorbj-0, lower-latin) ". "
}

.lst-kix_s8s4bw91u5cp-4 > li:before {
    content: "" counter(lst-ctn-kix_s8s4bw91u5cp-4, lower-roman) ". "
}

.lst-kix_s8s4bw91u5cp-8 > li:before {
    content: "" counter(lst-ctn-kix_s8s4bw91u5cp-8, decimal) ". "
}

ol.lst-kix_list_6-2.start {
    counter-reset: lst-ctn-kix_list_6-2 0
}

ol.lst-kix_bk1ome99d61y-3.start {
    counter-reset: lst-ctn-kix_bk1ome99d61y-3 0
}

.lst-kix_list_7-2 > li:before {
    content: "" counter(lst-ctn-kix_list_7-2, lower-latin) ". "
}

.lst-kix_j92y1nrorbj-2 > li:before {
    content: "" counter(lst-ctn-kix_j92y1nrorbj-2, lower-roman) ". "
}

.lst-kix_list_7-6 > li {
    counter-increment: lst-ctn-kix_list_7-6
}

ol.lst-kix_s8s4bw91u5cp-6.start {
    counter-reset: lst-ctn-kix_s8s4bw91u5cp-6 0
}

ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0
}

.lst-kix_s8s4bw91u5cp-0 > li:before {
    content: "" counter(lst-ctn-kix_s8s4bw91u5cp-0, lower-latin) ". "
}

ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 0
}

.lst-kix_s8s4bw91u5cp-2 > li:before {
    content: "" counter(lst-ctn-kix_s8s4bw91u5cp-2, decimal) ". "
}

.lst-kix_list_5-7 > li {
    counter-increment: lst-ctn-kix_list_5-7
}

.lst-kix_list_7-8 > li:before {
    content: "" counter(lst-ctn-kix_list_7-8, lower-roman) ". "
}

.lst-kix_list_4-7 > li {
    counter-increment: lst-ctn-kix_list_4-7
}

ol.lst-kix_j92y1nrorbj-3.start {
    counter-reset: lst-ctn-kix_j92y1nrorbj-3 0
}

.lst-kix_j92y1nrorbj-4 > li {
    counter-increment: lst-ctn-kix_j92y1nrorbj-4
}

ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0
}

.lst-kix_list_4-1 > li:before {
    content: "" counter(lst-ctn-kix_list_4-1, decimal) ". "
}

ul.lst-kix_yplpvhewoxry-1 {
    list-style-type: none
}

ol.lst-kix_ikuk634edow9-3.start {
    counter-reset: lst-ctn-kix_ikuk634edow9-3 0
}

ol.lst-kix_j92y1nrorbj-6.start {
    counter-reset: lst-ctn-kix_j92y1nrorbj-6 0
}

ul.lst-kix_yplpvhewoxry-7 {
    list-style-type: none
}

ul.lst-kix_yplpvhewoxry-6 {
    list-style-type: none
}

.lst-kix_list_4-3 > li:before {
    content: "" counter(lst-ctn-kix_list_4-3, lower-roman) ". "
}

.lst-kix_list_4-5 > li:before {
    content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". "
}

ul.lst-kix_yplpvhewoxry-8 {
    list-style-type: none
}

ul.lst-kix_yplpvhewoxry-3 {
    list-style-type: none
}

.lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8
}

ul.lst-kix_yplpvhewoxry-2 {
    list-style-type: none
}

ul.lst-kix_yplpvhewoxry-5 {
    list-style-type: none
}

ul.lst-kix_yplpvhewoxry-4 {
    list-style-type: none
}

ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0
}

.lst-kix_list_3-5 > li {
    counter-increment: lst-ctn-kix_list_3-5
}

ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0
}

ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0
}

.lst-kix_lxmvnmtqd0jr-5 > li:before {
    content: "" counter(lst-ctn-kix_lxmvnmtqd0jr-5, decimal) ". "
}

.lst-kix_lxmvnmtqd0jr-7 > li:before {
    content: "" counter(lst-ctn-kix_lxmvnmtqd0jr-7, lower-roman) ". "
}

ol.lst-kix_bk1ome99d61y-5.start {
    counter-reset: lst-ctn-kix_bk1ome99d61y-5 0
}

.lst-kix_list_6-4 > li {
    counter-increment: lst-ctn-kix_list_6-4
}

.lst-kix_lxmvnmtqd0jr-3 > li:before {
    content: "" counter(lst-ctn-kix_lxmvnmtqd0jr-3, lower-latin) ". "
}

ol.lst-kix_gn0wvmapmitf-6.start {
    counter-reset: lst-ctn-kix_gn0wvmapmitf-6 0
}

.lst-kix_j92y1nrorbj-3 > li {
    counter-increment: lst-ctn-kix_j92y1nrorbj-3
}

ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0
}

ol.lst-kix_list_7-6.start {
    counter-reset: lst-ctn-kix_list_7-6 0
}

.lst-kix_s8s4bw91u5cp-0 > li {
    counter-increment: lst-ctn-kix_s8s4bw91u5cp-0
}

ol.lst-kix_ikuk634edow9-6.start {
    counter-reset: lst-ctn-kix_ikuk634edow9-6 0
}

.lst-kix_lxmvnmtqd0jr-1 > li:before {
    content: "" counter(lst-ctn-kix_lxmvnmtqd0jr-1, lower-roman) ". "
}

ol.lst-kix_s8s4bw91u5cp-8.start {
    counter-reset: lst-ctn-kix_s8s4bw91u5cp-8 0
}

ol.lst-kix_ys6j0jd88rev-0.start {
    counter-reset: lst-ctn-kix_ys6j0jd88rev-0 0
}

.lst-kix_ys6j0jd88rev-2 > li {
    counter-increment: lst-ctn-kix_ys6j0jd88rev-2
}

ol.lst-kix_gn0wvmapmitf-5.start {
    counter-reset: lst-ctn-kix_gn0wvmapmitf-5 0
}

ol.lst-kix_list_5-8.start {
    counter-reset: lst-ctn-kix_list_5-8 0
}

.lst-kix_list_1-3 > li {
    counter-increment: lst-ctn-kix_list_1-3
}

li.li-bullet-4:before {
    margin-left: -52.9pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 52.9pt
}

.lst-kix_j92y1nrorbj-6 > li:before {
    content: "" counter(lst-ctn-kix_j92y1nrorbj-6, decimal) ". "
}

.lst-kix_j92y1nrorbj-8 > li:before {
    content: "" counter(lst-ctn-kix_j92y1nrorbj-8, lower-roman) ". "
}

ol.lst-kix_list_6-0.start {
    counter-reset: lst-ctn-kix_list_6-0 0
}

ol.lst-kix_f3r5o9u9gdu9-7 {
    list-style-type: none
}

ol.lst-kix_f3r5o9u9gdu9-6 {
    list-style-type: none
}

ol.lst-kix_f3r5o9u9gdu9-5 {
    list-style-type: none
}

.lst-kix_j92y1nrorbj-4 > li:before {
    content: "" counter(lst-ctn-kix_j92y1nrorbj-4, lower-latin) ". "
}

ol.lst-kix_f3r5o9u9gdu9-4 {
    list-style-type: none
}

ol.lst-kix_f3r5o9u9gdu9-3 {
    list-style-type: none
}

ol.lst-kix_f3r5o9u9gdu9-2 {
    list-style-type: none
}

.lst-kix_list_4-2 > li {
    counter-increment: lst-ctn-kix_list_4-2
}

ol.lst-kix_f3r5o9u9gdu9-1 {
    list-style-type: none
}

ol.lst-kix_f3r5o9u9gdu9-0 {
    list-style-type: none
}

ol.lst-kix_list_3-1 {
    list-style-type: none
}

ol.lst-kix_list_3-2 {
    list-style-type: none
}

ol.lst-kix_list_3-3 {
    list-style-type: none
}

ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 0
}

.lst-kix_list_5-1 > li {
    counter-increment: lst-ctn-kix_list_5-1
}

ol.lst-kix_list_3-4 {
    list-style-type: none
}

.lst-kix_lxmvnmtqd0jr-1 > li {
    counter-increment: lst-ctn-kix_lxmvnmtqd0jr-1
}

ol.lst-kix_list_3-0 {
    list-style-type: none
}

ol.lst-kix_f3r5o9u9gdu9-8 {
    list-style-type: none
}

.lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1
}

.lst-kix_list_7-1 > li {
    counter-increment: lst-ctn-kix_list_7-1
}

ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0
}

.lst-kix_list_3-0 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, upper-latin) ". "
}

ol.lst-kix_ys6j0jd88rev-5.start {
    counter-reset: lst-ctn-kix_ys6j0jd88rev-5 0
}

ol.lst-kix_bk1ome99d61y-7.start {
    counter-reset: lst-ctn-kix_bk1ome99d61y-7 0
}

.lst-kix_list_4-0 > li {
    counter-increment: lst-ctn-kix_list_4-0
}

.lst-kix_ikuk634edow9-0 > li:before {
    content: "" counter(lst-ctn-kix_ikuk634edow9-0, lower-latin) ". "
}

.lst-kix_ikuk634edow9-3 > li:before {
    content: "" counter(lst-ctn-kix_ikuk634edow9-3, lower-latin) ". "
}

.lst-kix_list_3-4 > li:before {
    content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". "
}

ol.lst-kix_f3r5o9u9gdu9-8.start {
    counter-reset: lst-ctn-kix_f3r5o9u9gdu9-8 0
}

.lst-kix_s8s4bw91u5cp-3 > li {
    counter-increment: lst-ctn-kix_s8s4bw91u5cp-3
}

.lst-kix_list_3-3 > li:before {
    content: "" counter(lst-ctn-kix_list_3-3, decimal) ". "
}

ol.lst-kix_list_3-5 {
    list-style-type: none
}

ol.lst-kix_list_3-6 {
    list-style-type: none
}

ol.lst-kix_list_3-7 {
    list-style-type: none
}

ol.lst-kix_list_3-8 {
    list-style-type: none
}

.lst-kix_ikuk634edow9-7 > li:before {
    content: "" counter(lst-ctn-kix_ikuk634edow9-7, lower-roman) ". "
}

.lst-kix_list_3-8 > li:before {
    content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". "
}

.lst-kix_ikuk634edow9-4 > li:before {
    content: "" counter(lst-ctn-kix_ikuk634edow9-4, lower-roman) ". "
}

.lst-kix_ikuk634edow9-8 > li:before {
    content: "" counter(lst-ctn-kix_ikuk634edow9-8, decimal) ". "
}

.lst-kix_j92y1nrorbj-1 > li {
    counter-increment: lst-ctn-kix_j92y1nrorbj-1
}

.lst-kix_list_3-7 > li:before {
    content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". "
}

.lst-kix_f3r5o9u9gdu9-4 > li {
    counter-increment: lst-ctn-kix_f3r5o9u9gdu9-4
}

ol.lst-kix_gn0wvmapmitf-0.start {
    counter-reset: lst-ctn-kix_gn0wvmapmitf-0 0
}

ol.lst-kix_list_2-2 {
    list-style-type: none
}

ol.lst-kix_list_2-3 {
    list-style-type: none
}

ol.lst-kix_list_2-4 {
    list-style-type: none
}

ol.lst-kix_list_7-2.start {
    counter-reset: lst-ctn-kix_list_7-2 0
}

ol.lst-kix_list_2-5 {
    list-style-type: none
}

ol.lst-kix_list_2-0 {
    list-style-type: none
}

ol.lst-kix_list_2-1 {
    list-style-type: none
}

.lst-kix_list_4-8 > li:before {
    content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". "
}

.lst-kix_j92y1nrorbj-8 > li {
    counter-increment: lst-ctn-kix_j92y1nrorbj-8
}

.lst-kix_list_4-7 > li:before {
    content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". "
}

.lst-kix_ys6j0jd88rev-1 > li {
    counter-increment: lst-ctn-kix_ys6j0jd88rev-1
}

ol.lst-kix_bk1ome99d61y-8.start {
    counter-reset: lst-ctn-kix_bk1ome99d61y-8 0
}

ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0
}

ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 0
}

ol.lst-kix_list_2-6 {
    list-style-type: none
}

ol.lst-kix_list_2-7 {
    list-style-type: none
}

ol.lst-kix_list_2-8 {
    list-style-type: none
}

ol.lst-kix_bk1ome99d61y-2 {
    list-style-type: none
}

ol.lst-kix_bk1ome99d61y-3 {
    list-style-type: none
}

ol.lst-kix_bk1ome99d61y-4 {
    list-style-type: none
}

ol.lst-kix_bk1ome99d61y-5 {
    list-style-type: none
}

ol.lst-kix_list_7-1.start {
    counter-reset: lst-ctn-kix_list_7-1 0
}

ol.lst-kix_bk1ome99d61y-0 {
    list-style-type: none
}

ol.lst-kix_bk1ome99d61y-1 {
    list-style-type: none
}

.lst-kix_bk1ome99d61y-5 > li {
    counter-increment: lst-ctn-kix_bk1ome99d61y-5
}

.lst-kix_gn0wvmapmitf-3 > li {
    counter-increment: lst-ctn-kix_gn0wvmapmitf-3
}

.lst-kix_f3r5o9u9gdu9-0 > li {
    counter-increment: lst-ctn-kix_f3r5o9u9gdu9-0
}

ol.lst-kix_bk1ome99d61y-6 {
    list-style-type: none
}

ol.lst-kix_bk1ome99d61y-7 {
    list-style-type: none
}

ol.lst-kix_bk1ome99d61y-8 {
    list-style-type: none
}

.lst-kix_ikuk634edow9-2 > li {
    counter-increment: lst-ctn-kix_ikuk634edow9-2
}

.lst-kix_s8s4bw91u5cp-1 > li {
    counter-increment: lst-ctn-kix_s8s4bw91u5cp-1
}

.lst-kix_list_7-0 > li:before {
    content: "" counter(lst-ctn-kix_list_7-0, decimal) ". "
}

ol.lst-kix_ys6j0jd88rev-4.start {
    counter-reset: lst-ctn-kix_ys6j0jd88rev-4 0
}

.lst-kix_list_2-2 > li {
    counter-increment: lst-ctn-kix_list_2-2
}

ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0
}

ol.lst-kix_list_5-0 {
    list-style-type: none
}

.lst-kix_list_3-7 > li {
    counter-increment: lst-ctn-kix_list_3-7
}

ol.lst-kix_list_5-1 {
    list-style-type: none
}

ol.lst-kix_list_5-2 {
    list-style-type: none
}

.lst-kix_s8s4bw91u5cp-8 > li {
    counter-increment: lst-ctn-kix_s8s4bw91u5cp-8
}

.lst-kix_list_2-4 > li:before {
    content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". "
}

.lst-kix_list_2-8 > li:before {
    content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". "
}

.lst-kix_s8s4bw91u5cp-5 > li:before {
    content: "" counter(lst-ctn-kix_s8s4bw91u5cp-5, decimal) ". "
}

.lst-kix_ys6j0jd88rev-6 > li:before {
    content: "" counter(lst-ctn-kix_ys6j0jd88rev-6, decimal) ". "
}

ol.lst-kix_gn0wvmapmitf-1.start {
    counter-reset: lst-ctn-kix_gn0wvmapmitf-1 0
}

.lst-kix_list_6-6 > li {
    counter-increment: lst-ctn-kix_list_6-6
}

.lst-kix_list_7-3 > li:before {
    content: "" counter(lst-ctn-kix_list_7-3, lower-roman) ". "
}

.lst-kix_j92y1nrorbj-1 > li:before {
    content: "" counter(lst-ctn-kix_j92y1nrorbj-1, lower-latin) ". "
}

ol.lst-kix_f3r5o9u9gdu9-1.start {
    counter-reset: lst-ctn-kix_f3r5o9u9gdu9-1 0
}

.lst-kix_s8s4bw91u5cp-1 > li:before {
    content: "" counter(lst-ctn-kix_s8s4bw91u5cp-1, lower-roman) ". "
}

.lst-kix_ys6j0jd88rev-2 > li:before {
    content: "" counter(lst-ctn-kix_ys6j0jd88rev-2, lower-roman) ". "
}

.lst-kix_lxmvnmtqd0jr-6 > li {
    counter-increment: lst-ctn-kix_lxmvnmtqd0jr-6
}

ol.lst-kix_list_5-7 {
    list-style-type: none
}

ol.lst-kix_list_5-8 {
    list-style-type: none
}

ol.lst-kix_list_5-3 {
    list-style-type: none
}

ol.lst-kix_list_5-4 {
    list-style-type: none
}

ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 0
}

ol.lst-kix_list_5-5 {
    list-style-type: none
}

ol.lst-kix_list_5-6 {
    list-style-type: none
}

.lst-kix_list_7-7 > li:before {
    content: "" counter(lst-ctn-kix_list_7-7, lower-latin) ". "
}

.lst-kix_list_5-8 > li {
    counter-increment: lst-ctn-kix_list_5-8
}

ol.lst-kix_f3r5o9u9gdu9-4.start {
    counter-reset: lst-ctn-kix_f3r5o9u9gdu9-4 0
}

.lst-kix_list_4-0 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) ". "
}

li.li-bullet-3:before {
    margin-left: -52.9pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 52.9pt
}

.lst-kix_list_4-4 > li:before {
    content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". "
}

ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0
}

.lst-kix_j92y1nrorbj-6 > li {
    counter-increment: lst-ctn-kix_j92y1nrorbj-6
}

.lst-kix_bk1ome99d61y-3 > li {
    counter-increment: lst-ctn-kix_bk1ome99d61y-3
}

ol.lst-kix_ys6j0jd88rev-6.start {
    counter-reset: lst-ctn-kix_ys6j0jd88rev-6 0
}

ol.lst-kix_list_7-0.start {
    counter-reset: lst-ctn-kix_list_7-0 0
}

ol.lst-kix_list_4-0 {
    list-style-type: none
}

.lst-kix_lxmvnmtqd0jr-6 > li:before {
    content: "" counter(lst-ctn-kix_lxmvnmtqd0jr-6, lower-latin) ". "
}

ol.lst-kix_f3r5o9u9gdu9-3.start {
    counter-reset: lst-ctn-kix_f3r5o9u9gdu9-3 0
}

ol.lst-kix_list_4-1 {
    list-style-type: none
}

.lst-kix_f3r5o9u9gdu9-2 > li {
    counter-increment: lst-ctn-kix_f3r5o9u9gdu9-2
}

ol.lst-kix_list_4-2 {
    list-style-type: none
}

ol.lst-kix_list_4-3 {
    list-style-type: none
}

.lst-kix_ikuk634edow9-4 > li {
    counter-increment: lst-ctn-kix_ikuk634edow9-4
}

.lst-kix_list_2-4 > li {
    counter-increment: lst-ctn-kix_list_2-4
}

ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 0
}

.lst-kix_ys6j0jd88rev-6 > li {
    counter-increment: lst-ctn-kix_ys6j0jd88rev-6
}

ol.lst-kix_ys6j0jd88rev-7.start {
    counter-reset: lst-ctn-kix_ys6j0jd88rev-7 0
}

.lst-kix_list_5-3 > li {
    counter-increment: lst-ctn-kix_list_5-3
}

ol.lst-kix_list_4-8 {
    list-style-type: none
}

.lst-kix_list_7-4 > li {
    counter-increment: lst-ctn-kix_list_7-4
}

.lst-kix_lxmvnmtqd0jr-2 > li:before {
    content: "" counter(lst-ctn-kix_lxmvnmtqd0jr-2, decimal) ". "
}

ol.lst-kix_lxmvnmtqd0jr-7 {
    list-style-type: none
}

.lst-kix_list_1-0 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) ". "
}

ol.lst-kix_lxmvnmtqd0jr-6 {
    list-style-type: none
}

.lst-kix_gn0wvmapmitf-5 > li {
    counter-increment: lst-ctn-kix_gn0wvmapmitf-5
}

ol.lst-kix_lxmvnmtqd0jr-8 {
    list-style-type: none
}

ol.lst-kix_list_4-4 {
    list-style-type: none
}

ol.lst-kix_lxmvnmtqd0jr-3 {
    list-style-type: none
}

ol.lst-kix_list_4-5 {
    list-style-type: none
}

ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0
}

ol.lst-kix_lxmvnmtqd0jr-2 {
    list-style-type: none
}

ol.lst-kix_list_4-6 {
    list-style-type: none
}

ol.lst-kix_lxmvnmtqd0jr-5 {
    list-style-type: none
}

ol.lst-kix_list_4-7 {
    list-style-type: none
}

ol.lst-kix_lxmvnmtqd0jr-4 {
    list-style-type: none
}

ol.lst-kix_ys6j0jd88rev-8.start {
    counter-reset: lst-ctn-kix_ys6j0jd88rev-8 0
}

.lst-kix_list_1-4 > li:before {
    content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". "
}

ol.lst-kix_lxmvnmtqd0jr-1 {
    list-style-type: none
}

ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 0
}

ol.lst-kix_lxmvnmtqd0jr-0 {
    list-style-type: none
}

.lst-kix_f3r5o9u9gdu9-3 > li:before {
    content: "" counter(lst-ctn-kix_f3r5o9u9gdu9-3, lower-latin) ". "
}

ol.lst-kix_f3r5o9u9gdu9-2.start {
    counter-reset: lst-ctn-kix_f3r5o9u9gdu9-2 0
}

.lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6
}

.lst-kix_list_2-0 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) ". "
}

ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0
}

.lst-kix_list_4-5 > li {
    counter-increment: lst-ctn-kix_list_4-5
}

.lst-kix_list_1-8 > li:before {
    content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". "
}

.lst-kix_j92y1nrorbj-5 > li:before {
    content: "" counter(lst-ctn-kix_j92y1nrorbj-5, lower-roman) ". "
}

.lst-kix_list_4-1 > li {
    counter-increment: lst-ctn-kix_list_4-1
}

ol.lst-kix_ikuk634edow9-7.start {
    counter-reset: lst-ctn-kix_ikuk634edow9-7 0
}

ol.lst-kix_f3r5o9u9gdu9-5.start {
    counter-reset: lst-ctn-kix_f3r5o9u9gdu9-5 0
}

ol.lst-kix_lxmvnmtqd0jr-2.start {
    counter-reset: lst-ctn-kix_lxmvnmtqd0jr-2 0
}

.lst-kix_f3r5o9u9gdu9-5 > li {
    counter-increment: lst-ctn-kix_f3r5o9u9gdu9-5
}

ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0
}

.lst-kix_s8s4bw91u5cp-2 > li {
    counter-increment: lst-ctn-kix_s8s4bw91u5cp-2
}

.lst-kix_f3r5o9u9gdu9-8 > li:before {
    content: "" counter(lst-ctn-kix_f3r5o9u9gdu9-8, decimal) ". "
}

ol.lst-kix_list_6-6.start {
    counter-reset: lst-ctn-kix_list_6-6 0
}

.lst-kix_f3r5o9u9gdu9-7 > li:before {
    content: "" counter(lst-ctn-kix_f3r5o9u9gdu9-7, lower-roman) ". "
}

ol.lst-kix_j92y1nrorbj-7.start {
    counter-reset: lst-ctn-kix_j92y1nrorbj-7 0
}

.lst-kix_list_7-0 > li {
    counter-increment: lst-ctn-kix_list_7-0
}

ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0
}

ol.lst-kix_j92y1nrorbj-8 {
    list-style-type: none
}

ol.lst-kix_j92y1nrorbj-7 {
    list-style-type: none
}

ol.lst-kix_j92y1nrorbj-6 {
    list-style-type: none
}

ol.lst-kix_j92y1nrorbj-5 {
    list-style-type: none
}

ol.lst-kix_j92y1nrorbj-4 {
    list-style-type: none
}

ol.lst-kix_j92y1nrorbj-3 {
    list-style-type: none
}

ol.lst-kix_j92y1nrorbj-2 {
    list-style-type: none
}

ol.lst-kix_j92y1nrorbj-1 {
    list-style-type: none
}

ol.lst-kix_j92y1nrorbj-0 {
    list-style-type: none
}

.lst-kix_list_2-3 > li {
    counter-increment: lst-ctn-kix_list_2-3
}

ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0
}

.lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2
}

.lst-kix_list_5-2 > li {
    counter-increment: lst-ctn-kix_list_5-2
}

ol.lst-kix_s8s4bw91u5cp-7.start {
    counter-reset: lst-ctn-kix_s8s4bw91u5cp-7 0
}

.lst-kix_lxmvnmtqd0jr-4 > li {
    counter-increment: lst-ctn-kix_lxmvnmtqd0jr-4
}

ol.lst-kix_ikuk634edow9-2.start {
    counter-reset: lst-ctn-kix_ikuk634edow9-2 0
}

ol.lst-kix_j92y1nrorbj-2.start {
    counter-reset: lst-ctn-kix_j92y1nrorbj-2 0
}

ol.lst-kix_lxmvnmtqd0jr-7.start {
    counter-reset: lst-ctn-kix_lxmvnmtqd0jr-7 0
}

ol.lst-kix_f3r5o9u9gdu9-0.start {
    counter-reset: lst-ctn-kix_f3r5o9u9gdu9-0 0
}

ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0
}

ol.lst-kix_ikuk634edow9-3 {
    list-style-type: none
}

ol.lst-kix_ikuk634edow9-2 {
    list-style-type: none
}

ol.lst-kix_ikuk634edow9-1 {
    list-style-type: none
}

ol.lst-kix_ikuk634edow9-0 {
    list-style-type: none
}

.lst-kix_list_3-0 > li {
    counter-increment: lst-ctn-kix_list_3-0
}

.lst-kix_lxmvnmtqd0jr-0 > li {
    counter-increment: lst-ctn-kix_lxmvnmtqd0jr-0
}

.lst-kix_bk1ome99d61y-8 > li {
    counter-increment: lst-ctn-kix_bk1ome99d61y-8
}

ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 0
}

li.li-bullet-2:before {
    margin-left: -53pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 53pt
}

ol.lst-kix_gn0wvmapmitf-2.start {
    counter-reset: lst-ctn-kix_gn0wvmapmitf-2 0
}

.lst-kix_j92y1nrorbj-2 > li {
    counter-increment: lst-ctn-kix_j92y1nrorbj-2
}

.lst-kix_ys6j0jd88rev-4 > li {
    counter-increment: lst-ctn-kix_ys6j0jd88rev-4
}

.lst-kix_gn0wvmapmitf-0 > li {
    counter-increment: lst-ctn-kix_gn0wvmapmitf-0
}

ol.lst-kix_ikuk634edow9-7 {
    list-style-type: none
}

ol.lst-kix_ikuk634edow9-6 {
    list-style-type: none
}

ol.lst-kix_ikuk634edow9-5 {
    list-style-type: none
}

ol.lst-kix_ikuk634edow9-4 {
    list-style-type: none
}

ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0
}

ol.lst-kix_ikuk634edow9-8 {
    list-style-type: none
}

ol.lst-kix_list_1-3 {
    list-style-type: none
}

.lst-kix_ys6j0jd88rev-7 > li:before {
    content: "" counter(lst-ctn-kix_ys6j0jd88rev-7, lower-latin) ". "
}

.lst-kix_bk1ome99d61y-1 > li {
    counter-increment: lst-ctn-kix_bk1ome99d61y-1
}

ol.lst-kix_list_1-4 {
    list-style-type: none
}

.lst-kix_list_2-7 > li:before {
    content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". "
}

.lst-kix_list_2-7 > li {
    counter-increment: lst-ctn-kix_list_2-7
}

ol.lst-kix_list_1-5 {
    list-style-type: none
}

.lst-kix_gn0wvmapmitf-7 > li {
    counter-increment: lst-ctn-kix_gn0wvmapmitf-7
}

ol.lst-kix_list_1-6 {
    list-style-type: none
}

.lst-kix_lxmvnmtqd0jr-7 > li {
    counter-increment: lst-ctn-kix_lxmvnmtqd0jr-7
}

.lst-kix_ys6j0jd88rev-5 > li:before {
    content: "" counter(lst-ctn-kix_ys6j0jd88rev-5, lower-roman) ". "
}

ol.lst-kix_list_1-0 {
    list-style-type: none
}

.lst-kix_list_2-5 > li:before {
    content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". "
}

ol.lst-kix_list_1-1 {
    list-style-type: none
}

ol.lst-kix_list_1-2 {
    list-style-type: none
}

.lst-kix_ys6j0jd88rev-3 > li:before {
    content: "" counter(lst-ctn-kix_ys6j0jd88rev-3, decimal) ". "
}

ol.lst-kix_gn0wvmapmitf-6 {
    list-style-type: none
}

ol.lst-kix_gn0wvmapmitf-5 {
    list-style-type: none
}

ol.lst-kix_gn0wvmapmitf-8 {
    list-style-type: none
}

ol.lst-kix_gn0wvmapmitf-7 {
    list-style-type: none
}

.lst-kix_ikuk634edow9-6 > li {
    counter-increment: lst-ctn-kix_ikuk634edow9-6
}

.lst-kix_ys6j0jd88rev-1 > li:before {
    content: "" counter(lst-ctn-kix_ys6j0jd88rev-1, lower-latin) ". "
}

ol.lst-kix_gn0wvmapmitf-4.start {
    counter-reset: lst-ctn-kix_gn0wvmapmitf-4 0
}

.lst-kix_list_7-7 > li {
    counter-increment: lst-ctn-kix_list_7-7
}

.lst-kix_ikuk634edow9-7 > li {
    counter-increment: lst-ctn-kix_ikuk634edow9-7
}

ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0
}

ol.lst-kix_list_1-7 {
    list-style-type: none
}

ol.lst-kix_list_1-8 {
    list-style-type: none
}

.lst-kix_list_7-8 > li {
    counter-increment: lst-ctn-kix_list_7-8
}

.lst-kix_ys6j0jd88rev-8 > li {
    counter-increment: lst-ctn-kix_ys6j0jd88rev-8
}

li.li-bullet-1:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

ol.lst-kix_gn0wvmapmitf-7.start {
    counter-reset: lst-ctn-kix_gn0wvmapmitf-7 0
}

.lst-kix_list_2-6 > li {
    counter-increment: lst-ctn-kix_list_2-6
}

ol.lst-kix_list_7-3.start {
    counter-reset: lst-ctn-kix_list_7-3 0
}

.lst-kix_bk1ome99d61y-0 > li {
    counter-increment: lst-ctn-kix_bk1ome99d61y-0
}

ol.lst-kix_ys6j0jd88rev-3.start {
    counter-reset: lst-ctn-kix_ys6j0jd88rev-3 0
}

ol.lst-kix_list_5-7.start {
    counter-reset: lst-ctn-kix_list_5-7 0
}

ol.lst-kix_bk1ome99d61y-6.start {
    counter-reset: lst-ctn-kix_bk1ome99d61y-6 0
}

.lst-kix_list_5-5 > li {
    counter-increment: lst-ctn-kix_list_5-5
}

ol.lst-kix_f3r5o9u9gdu9-7.start {
    counter-reset: lst-ctn-kix_f3r5o9u9gdu9-7 0
}

.lst-kix_list_3-4 > li {
    counter-increment: lst-ctn-kix_list_3-4
}

.lst-kix_s8s4bw91u5cp-5 > li {
    counter-increment: lst-ctn-kix_s8s4bw91u5cp-5
}

.lst-kix_list_6-3 > li {
    counter-increment: lst-ctn-kix_list_6-3
}

ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0
}

ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0
}

ol.lst-kix_list_6-1.start {
    counter-reset: lst-ctn-kix_list_6-1 0
}

.lst-kix_list_1-1 > li:before {
    content: "" counter(lst-ctn-kix_list_1-1, decimal) ". "
}

.lst-kix_s8s4bw91u5cp-6 > li {
    counter-increment: lst-ctn-kix_s8s4bw91u5cp-6
}

.lst-kix_list_1-3 > li:before {
    content: "" counter(lst-ctn-kix_list_1-3, lower-roman) ". "
}

.lst-kix_f3r5o9u9gdu9-0 > li:before {
    content: "" counter(lst-ctn-kix_f3r5o9u9gdu9-0, lower-latin) ". "
}

.lst-kix_f3r5o9u9gdu9-2 > li:before {
    content: "" counter(lst-ctn-kix_f3r5o9u9gdu9-2, decimal) ". "
}

ol.lst-kix_bk1ome99d61y-4.start {
    counter-reset: lst-ctn-kix_bk1ome99d61y-4 0
}

ol.lst-kix_ys6j0jd88rev-1.start {
    counter-reset: lst-ctn-kix_ys6j0jd88rev-1 0
}

.lst-kix_list_4-8 > li {
    counter-increment: lst-ctn-kix_list_4-8
}

.lst-kix_list_1-7 > li:before {
    content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". "
}

.lst-kix_gn0wvmapmitf-8 > li {
    counter-increment: lst-ctn-kix_gn0wvmapmitf-8
}

ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0
}

.lst-kix_f3r5o9u9gdu9-4 > li:before {
    content: "" counter(lst-ctn-kix_f3r5o9u9gdu9-4, lower-roman) ". "
}

.lst-kix_f3r5o9u9gdu9-6 > li:before {
    content: "" counter(lst-ctn-kix_f3r5o9u9gdu9-6, lower-latin) ". "
}

ol.lst-kix_j92y1nrorbj-5.start {
    counter-reset: lst-ctn-kix_j92y1nrorbj-5 0
}

.lst-kix_list_1-5 > li:before {
    content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". "
}

.lst-kix_lxmvnmtqd0jr-8 > li {
    counter-increment: lst-ctn-kix_lxmvnmtqd0jr-8
}

.lst-kix_list_5-6 > li {
    counter-increment: lst-ctn-kix_list_5-6
}

ol.lst-kix_list_7-5.start {
    counter-reset: lst-ctn-kix_list_7-5 0
}

.lst-kix_list_2-1 > li:before {
    content: "" counter(lst-ctn-kix_list_2-1, decimal) ". "
}

.lst-kix_list_2-3 > li:before {
    content: "" counter(lst-ctn-kix_list_2-3, lower-roman) ". "
}

ol.lst-kix_ikuk634edow9-5.start {
    counter-reset: lst-ctn-kix_ikuk634edow9-5 0
}

.lst-kix_list_3-1 > li {
    counter-increment: lst-ctn-kix_list_3-1
}

.lst-kix_gn0wvmapmitf-1 > li {
    counter-increment: lst-ctn-kix_gn0wvmapmitf-1
}

ol.lst-kix_lxmvnmtqd0jr-5.start {
    counter-reset: lst-ctn-kix_lxmvnmtqd0jr-5 0
}

.lst-kix_bk1ome99d61y-7 > li {
    counter-increment: lst-ctn-kix_bk1ome99d61y-7
}

.lst-kix_ys6j0jd88rev-3 > li {
    counter-increment: lst-ctn-kix_ys6j0jd88rev-3
}

ol.lst-kix_list_7-7.start {
    counter-reset: lst-ctn-kix_list_7-7 0
}

.lst-kix_list_3-1 > li:before {
    content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ". "
}

.lst-kix_list_3-2 > li:before {
    content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". "
}

.lst-kix_ikuk634edow9-0 > li {
    counter-increment: lst-ctn-kix_ikuk634edow9-0
}

ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0
}

ol.lst-kix_ikuk634edow9-4.start {
    counter-reset: lst-ctn-kix_ikuk634edow9-4 0
}

.lst-kix_list_6-0 > li {
    counter-increment: lst-ctn-kix_list_6-0
}

.lst-kix_list_3-5 > li:before {
    content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". "
}

.lst-kix_f3r5o9u9gdu9-6 > li {
    counter-increment: lst-ctn-kix_f3r5o9u9gdu9-6
}

ol.lst-kix_j92y1nrorbj-4.start {
    counter-reset: lst-ctn-kix_j92y1nrorbj-4 0
}

.lst-kix_ikuk634edow9-1 > li:before {
    content: "" counter(lst-ctn-kix_ikuk634edow9-1, lower-roman) ". "
}

.lst-kix_ikuk634edow9-2 > li:before {
    content: "" counter(lst-ctn-kix_ikuk634edow9-2, decimal) ". "
}

ol.lst-kix_s8s4bw91u5cp-4.start {
    counter-reset: lst-ctn-kix_s8s4bw91u5cp-4 0
}

.lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0
}

.lst-kix_list_3-6 > li:before {
    content: "" counter(lst-ctn-kix_list_3-6, decimal) ". "
}

.lst-kix_ikuk634edow9-5 > li:before {
    content: "" counter(lst-ctn-kix_ikuk634edow9-5, decimal) ". "
}

.lst-kix_ikuk634edow9-6 > li:before {
    content: "" counter(lst-ctn-kix_ikuk634edow9-6, lower-latin) ". "
}

ol.lst-kix_list_5-0.start {
    counter-reset: lst-ctn-kix_list_5-0 0
}

ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0
}

ol.lst-kix_lxmvnmtqd0jr-0.start {
    counter-reset: lst-ctn-kix_lxmvnmtqd0jr-0 0
}

.lst-kix_list_4-4 > li {
    counter-increment: lst-ctn-kix_list_4-4
}

ol.lst-kix_list_6-4.start {
    counter-reset: lst-ctn-kix_list_6-4 0
}

ol.lst-kix_s8s4bw91u5cp-5.start {
    counter-reset: lst-ctn-kix_s8s4bw91u5cp-5 0
}

ol.lst-kix_list_5-6.start {
    counter-reset: lst-ctn-kix_list_5-6 0
}

ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0
}

.lst-kix_list_7-3 > li {
    counter-increment: lst-ctn-kix_list_7-3
}

ol.lst-kix_bk1ome99d61y-2.start {
    counter-reset: lst-ctn-kix_bk1ome99d61y-2 0
}

ol.lst-kix_yplpvhewoxry-0.start {
    counter-reset: lst-ctn-kix_yplpvhewoxry-0 0
}

ol.lst-kix_list_7-8.start {
    counter-reset: lst-ctn-kix_list_7-8 0
}

.lst-kix_list_3-3 > li {
    counter-increment: lst-ctn-kix_list_3-3
}

.lst-kix_lxmvnmtqd0jr-3 > li {
    counter-increment: lst-ctn-kix_lxmvnmtqd0jr-3
}

ol.lst-kix_list_6-3.start {
    counter-reset: lst-ctn-kix_list_6-3 0
}

ol.lst-kix_gn0wvmapmitf-2 {
    list-style-type: none
}

ol.lst-kix_gn0wvmapmitf-1 {
    list-style-type: none
}

.lst-kix_ys6j0jd88rev-7 > li {
    counter-increment: lst-ctn-kix_ys6j0jd88rev-7
}

ol.lst-kix_bk1ome99d61y-1.start {
    counter-reset: lst-ctn-kix_bk1ome99d61y-1 0
}

.lst-kix_s8s4bw91u5cp-7 > li {
    counter-increment: lst-ctn-kix_s8s4bw91u5cp-7
}

ol.lst-kix_gn0wvmapmitf-4 {
    list-style-type: none
}

ol.lst-kix_gn0wvmapmitf-3 {
    list-style-type: none
}

ol.lst-kix_list_5-5.start {
    counter-reset: lst-ctn-kix_list_5-5 0
}

.lst-kix_j92y1nrorbj-5 > li {
    counter-increment: lst-ctn-kix_j92y1nrorbj-5
}

ol.lst-kix_gn0wvmapmitf-0 {
    list-style-type: none
}

.lst-kix_list_6-2 > li {
    counter-increment: lst-ctn-kix_list_6-2
}

.lst-kix_list_2-6 > li:before {
    content: "" counter(lst-ctn-kix_list_2-6, decimal) ". "
}

ol.lst-kix_ys6j0jd88rev-8 {
    list-style-type: none
}

.lst-kix_list_7-1 > li:before {
    content: "" counter(lst-ctn-kix_list_7-1, decimal) ". "
}

.lst-kix_list_7-5 > li:before {
    content: "" counter(lst-ctn-kix_list_7-5, lower-roman) ". "
}

ol.lst-kix_ys6j0jd88rev-4 {
    list-style-type: none
}

ol.lst-kix_ys6j0jd88rev-5 {
    list-style-type: none
}

ol.lst-kix_ys6j0jd88rev-6 {
    list-style-type: none
}

ol.lst-kix_yplpvhewoxry-0 {
    list-style-type: none
}

ol.lst-kix_ys6j0jd88rev-7 {
    list-style-type: none
}

ol.lst-kix_ys6j0jd88rev-0 {
    list-style-type: none
}

ol.lst-kix_ys6j0jd88rev-1 {
    list-style-type: none
}

.lst-kix_s8s4bw91u5cp-7 > li:before {
    content: "" counter(lst-ctn-kix_s8s4bw91u5cp-7, lower-roman) ". "
}

ol.lst-kix_ys6j0jd88rev-2 {
    list-style-type: none
}

.lst-kix_ys6j0jd88rev-4 > li:before {
    content: "" counter(lst-ctn-kix_ys6j0jd88rev-4, lower-latin) ". "
}

ol.lst-kix_list_5-4.start {
    counter-reset: lst-ctn-kix_list_5-4 0
}

ol.lst-kix_ys6j0jd88rev-3 {
    list-style-type: none
}

ol.lst-kix_s8s4bw91u5cp-3 {
    list-style-type: none
}

ol.lst-kix_s8s4bw91u5cp-0.start {
    counter-reset: lst-ctn-kix_s8s4bw91u5cp-0 0
}

ol.lst-kix_s8s4bw91u5cp-4 {
    list-style-type: none
}

ol.lst-kix_s8s4bw91u5cp-5 {
    list-style-type: none
}

ol.lst-kix_s8s4bw91u5cp-6 {
    list-style-type: none
}

ol.lst-kix_list_5-1.start {
    counter-reset: lst-ctn-kix_list_5-1 0
}

ol.lst-kix_s8s4bw91u5cp-0 {
    list-style-type: none
}

ol.lst-kix_s8s4bw91u5cp-1 {
    list-style-type: none
}

ol.lst-kix_bk1ome99d61y-0.start {
    counter-reset: lst-ctn-kix_bk1ome99d61y-0 0
}

ol.lst-kix_s8s4bw91u5cp-2 {
    list-style-type: none
}

.lst-kix_list_6-7 > li {
    counter-increment: lst-ctn-kix_list_6-7
}

.lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7
}

.lst-kix_s8s4bw91u5cp-3 > li:before {
    content: "" counter(lst-ctn-kix_s8s4bw91u5cp-3, lower-latin) ". "
}

.lst-kix_ys6j0jd88rev-0 > li:before {
    content: "" counter(lst-ctn-kix_ys6j0jd88rev-0, lower-latin) ". "
}

ol.lst-kix_s8s4bw91u5cp-3.start {
    counter-reset: lst-ctn-kix_s8s4bw91u5cp-3 0
}

ol.lst-kix_lxmvnmtqd0jr-6.start {
    counter-reset: lst-ctn-kix_lxmvnmtqd0jr-6 0
}

.lst-kix_list_7-5 > li {
    counter-increment: lst-ctn-kix_list_7-5
}

.lst-kix_list_3-8 > li {
    counter-increment: lst-ctn-kix_list_3-8
}

ol.lst-kix_s8s4bw91u5cp-7 {
    list-style-type: none
}

ol.lst-kix_list_6-8.start {
    counter-reset: lst-ctn-kix_list_6-8 0
}

ol.lst-kix_s8s4bw91u5cp-8 {
    list-style-type: none
}

.lst-kix_list_4-6 > li {
    counter-increment: lst-ctn-kix_list_4-6
}

ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0
}

ol.lst-kix_j92y1nrorbj-0.start {
    counter-reset: lst-ctn-kix_j92y1nrorbj-0 0
}

.lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5
}

ol.lst-kix_list_6-5.start {
    counter-reset: lst-ctn-kix_list_6-5 0
}

.lst-kix_list_4-2 > li:before {
    content: "" counter(lst-ctn-kix_list_4-2, lower-latin) ". "
}

.lst-kix_list_4-6 > li:before {
    content: "" counter(lst-ctn-kix_list_4-6, decimal) ". "
}

ol.lst-kix_ikuk634edow9-0.start {
    counter-reset: lst-ctn-kix_ikuk634edow9-0 0
}

.lst-kix_ys6j0jd88rev-0 > li {
    counter-increment: lst-ctn-kix_ys6j0jd88rev-0
}

.lst-kix_lxmvnmtqd0jr-8 > li:before {
    content: "" counter(lst-ctn-kix_lxmvnmtqd0jr-8, decimal) ". "
}

.lst-kix_f3r5o9u9gdu9-1 > li {
    counter-increment: lst-ctn-kix_f3r5o9u9gdu9-1
}

.lst-kix_gn0wvmapmitf-4 > li {
    counter-increment: lst-ctn-kix_gn0wvmapmitf-4
}

.lst-kix_bk1ome99d61y-4 > li {
    counter-increment: lst-ctn-kix_bk1ome99d61y-4
}

.lst-kix_f3r5o9u9gdu9-8 > li {
    counter-increment: lst-ctn-kix_f3r5o9u9gdu9-8
}

ol.lst-kix_list_6-7.start {
    counter-reset: lst-ctn-kix_list_6-7 0
}

ol.lst-kix_s8s4bw91u5cp-1.start {
    counter-reset: lst-ctn-kix_s8s4bw91u5cp-1 0
}

.lst-kix_lxmvnmtqd0jr-4 > li:before {
    content: "" counter(lst-ctn-kix_lxmvnmtqd0jr-4, lower-roman) ". "
}

ol.lst-kix_lxmvnmtqd0jr-3.start {
    counter-reset: lst-ctn-kix_lxmvnmtqd0jr-3 0
}

.lst-kix_ikuk634edow9-3 > li {
    counter-increment: lst-ctn-kix_ikuk634edow9-3
}

.lst-kix_lxmvnmtqd0jr-0 > li:before {
    content: "" counter(lst-ctn-kix_lxmvnmtqd0jr-0, lower-latin) ". "
}

.lst-kix_list_1-2 > li:before {
    content: "" counter(lst-ctn-kix_list_1-2, lower-latin) ". "
}

.lst-kix_ys6j0jd88rev-5 > li {
    counter-increment: lst-ctn-kix_ys6j0jd88rev-5
}

.lst-kix_j92y1nrorbj-7 > li {
    counter-increment: lst-ctn-kix_j92y1nrorbj-7
}

.lst-kix_f3r5o9u9gdu9-1 > li:before {
    content: "" counter(lst-ctn-kix_f3r5o9u9gdu9-1, lower-roman) ". "
}

.lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0
}

ol.lst-kix_s8s4bw91u5cp-2.start {
    counter-reset: lst-ctn-kix_s8s4bw91u5cp-2 0
}

.lst-kix_f3r5o9u9gdu9-5 > li:before {
    content: "" counter(lst-ctn-kix_f3r5o9u9gdu9-5, decimal) ". "
}

.lst-kix_lxmvnmtqd0jr-5 > li {
    counter-increment: lst-ctn-kix_lxmvnmtqd0jr-5
}

.lst-kix_list_1-6 > li:before {
    content: "" counter(lst-ctn-kix_list_1-6, decimal) ". "
}

li.li-bullet-0:before {
    margin-left: -52.9pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 52.9pt
}

.lst-kix_j92y1nrorbj-3 > li:before {
    content: "" counter(lst-ctn-kix_j92y1nrorbj-3, decimal) ". "
}

.lst-kix_j92y1nrorbj-7 > li:before {
    content: "" counter(lst-ctn-kix_j92y1nrorbj-7, lower-latin) ". "
}

.lst-kix_list_2-2 > li:before {
    content: "" counter(lst-ctn-kix_list_2-2, lower-latin) ". "
}

ol.lst-kix_lxmvnmtqd0jr-4.start {
    counter-reset: lst-ctn-kix_lxmvnmtqd0jr-4 0
}

ol.lst-kix_list_5-2.start {
    counter-reset: lst-ctn-kix_list_5-2 0
}

.lst-kix_ys6j0jd88rev-8 > li:before {
    content: "" counter(lst-ctn-kix_ys6j0jd88rev-8, lower-roman) ". "
}

ol {
    margin: 0;
    padding: 0
}

table td, table th {
    padding: 0
}

.c2 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 34.9pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c22 {
    padding-top: 20pt;
    padding-bottom: 6pt;
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c4 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c16 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c33 {
    padding-top: 0pt;
    padding-bottom: 15pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c5 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal
}

.c30 {
    padding-top: 15pt;
    padding-bottom: 15pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: right
}

.c17 {
    padding-top: 14pt;
    padding-bottom: 4pt;
    line-height: 1.5999999999999999;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c28 {
    padding-top: 15pt;
    padding-bottom: 15pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c13 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal
}

.c9 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #0000ff;
    text-decoration: underline
}

.c21 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline
}

.c24 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline
}

.c19 {
    margin-left: 35.8pt;
    padding-left: 35pt;
    page-break-after: avoid
}

.c35 {
    background-color: #ffffff;
    max-width: 451.5pt;
    padding: 72pt 72pt 72pt 72pt
}

.c20 {
    color: inherit;
    text-decoration: inherit
}

.c29 {
    vertical-align: baseline;
    font-style: normal
}

.c0 {
    padding: 0;
    margin: 0
}

.c37 {
    margin-left: 108pt;
    text-indent: 36pt
}

.c23 {
    font-family: "Arial";
    font-weight: 400
}

.c10 {
    margin-left: 72pt;
    padding-left: 0pt
}

.c7 {
    margin-left: 108pt;
    padding-left: 0pt
}

.c36 {
    margin-left: 144pt;
    padding-left: 0pt
}

.c8 {
    margin-left: 36pt;
    padding-left: 0pt
}

.c26 {
    margin-left: 36pt;
    text-indent: 36pt
}

.c14 {
    font-size: 11pt
}

.c18 {
    margin-left: 72pt
}

.c1 {
    font-size: 10pt
}

.c3 {
    font-size: 8pt
}

.c34 {
    font-style: italic
}

.c6 {
    font-weight: 700
}

.c31 {
    color: #000000
}

.c15 {
    font-size: 14pt
}

.c32 {
    margin-left: 36pt
}

.c38 {
    margin-left: 90pt
}

.c12 {
    margin-left: 70.9pt
}

.c27 {
    page-break-after: avoid
}

.c25 {
    font-size: 13.5pt
}

.c39 {
    background-color: #ffff00
}

.c11 {
    height: 11pt
}

.title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#terms-conditions-container li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

#terms-conditions-container p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

#terms-conditions-container h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#terms-conditions-container h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#terms-conditions-container h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#terms-conditions-container h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#terms-conditions-container h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#terms-conditions-container h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}
